import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import {getTotalListFilters} from '../../redux/propertiesDucks'
import { updateFields,formTasaciones_Tokko } from '../../redux/contactDucks'
import toast, { Toaster } from 'react-hot-toast';
import { graphql, useStaticQuery } from 'gatsby';
import ReCAPTCHA from "react-google-recaptcha";

const Form = ({totalListFilters,dispatch}) => {
    
    const type = useRef()
    const operation = useRef()
    const [datos, setDatos] = useState({
        nombre:'',
        email:'',
        ubicacion:'',
        telefono:'',
        sup_cub:'',
        sup_tot:'',
        rooms:'',
        garage:'',
        age:'',
        amenities:[]
    })
        
    const [disabled, setDisabled] = useState(true);
    const { realEstate } = useStaticQuery(graphql`
        query {
            realEstate {
                keys {
                    captcha
                }
            }
    }`)
                
    const handleInputChange = (event) => {
        if(event.target.name == 'amenities'){
            if(datos.amenities.find(element => element === event.target.value)){
                const newAmenities = datos.amenities.filter(element => element !== event.target.value)
                setDatos({
                    ...datos,
                    [event.target.name] : newAmenities, 
                })
                return null
            }
        setDatos({
            ...datos,
            amenities : [...datos.amenities,event.target.value], 
        })
            return null
        }
        setDatos({
            ...datos,
            [event.target.name] : event.target.value, 
        })
        
    }
    const clearForm= ()=>{
        setDatos({
        nombre:'',
        email:'',
        ubicacion:'',
        sup_cub:'',
        telefono:'',
        sup_tot:'',
        rooms:'',
        garage:'',
        age:'',
        amenities:[]
        })
    }
    const sendForm = (event) => {
        event.preventDefault()
        dispatch(formTasaciones_Tokko(type.current.value,operation.current.value))
        window.scrollTo(0, 0)
        document.getElementById("formContact").reset()
        return toast.success("¡Mensaje enviado correctamente!") 
    }
    useEffect(() => {
        const update = () => {
            dispatch(updateFields(datos))
        }
        update()
      
    }, [datos])
    useEffect(() => {
        if(!realEstate?.keys?.captcha){
            setDisabled(false)
          }
        else{
            setDisabled(true)
        }
        dispatch(getTotalListFilters())
    },[])
    return (
        <>
        <form onSubmit={sendForm} id="formContact" class="row content-form ajax-form">
            <div class="col-lg-11">
                <div class="row">
                    <div class="col-lg-6 pr-lg-3 pr-lg-2 mb-4 mb-lg-4">
                        <input autocomplete="off" onChange={handleInputChange} type="text" required placeholder="Ubicación" name="ubicacion" id="ubicacion" />
                    </div>
                    <div class="col-lg-6 pr-lg-3 pl-lg-2 mb-4 mb-lg-4 position-relative">
                        <select required ref={operation} name="operation">
                            <option value="">Tipo de operación</option>
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 1) ? <option value="Venta">Venta</option> : ''}
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 2) ? <option value="Alquiler">Alquiler</option> : ''}
                            {totalListFilters.data && totalListFilters.data.objects.operation_types.find(element => element.operation_type === 3) ? <option value="Alquiler Temporario">Alquiler Temporario</option> : ''}
                        </select>
                        <i></i>
                    </div>
                    <div class="col-lg-6 pr-lg-3 mb-4 mb-lg-4 position-relative">
                        <select ref={type} name="type">
                            <option value="">Tipo de propiedad</option>
                            {totalListFilters.data ? 
                                totalListFilters.data.objects.property_types.map((item,index) => (
                                    <option value={item.type}>{item.type}</option>
                                    )
                                )
                                :''
                            }
                        </select>
                        <i></i>
                    </div>
                    <div class="col-lg-6 camp mb-4">
                        <input autocomplete="off" onChange={handleInputChange} type="number" required name="telefono"  placeholder="Telefono" />
                    </div>
                    <div class="col-lg-6 camp mb-4 mb-lg-2">
                        <input autocomplete="off" onChange={handleInputChange} type="text" required name="nombre"  placeholder="Nombre y Apellido" />
                    </div>
                    <div class="col-lg-6 camp">
                        <input autocomplete="off" onChange={handleInputChange} type="text" required name="email"  placeholder="E-Mail*" />
                    </div>
                    <div class="col-lg-12 camp d-flex flex-column end-form">
                        <div className="d-lg-flex w-100 justify-content-between">
                        {
                            realEstate?.keys?.captcha ? 
                            <ReCAPTCHA
                            sitekey={realEstate?.keys?.captcha ? realEstate?.keys?.captcha : 0}
                            onChange ={() => setDisabled(false)}
                            /> 
                            : 
                            '' 
                        }
                        <p class="ms-lg-4 d-none d-lg-block campo-obligatorio">Campo obligatorio*</p>
                        </div>
                        <input type="submit" disabled={disabled} value="ENVIAR" class="btn btn-fifth submit mt-5 " id="submit-info" />
                    </div>
                </div>
            </div>
        </form>
        <div id="alert-message"></div>
    </>
    )
}

export default connect(state => ({
    totalListFilters: state.properties.totalListFilters,
}),null)(Form);
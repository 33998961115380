import React from 'react'
import Formulario from './form'
import Breadcrumbs from "../breadcrumbs";
import { graphql, useStaticQuery } from 'gatsby';

import { getBrText, getMessageWhatsapp } from '../../helpers/helper.rendering'
// import logo1 from "../../images/dark-CIA.png"
// import logo2 from "../../images/SOM.png"

const Main = () => {
    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
              global_email
              global_phone
              global_whatsapp
              global_broker_id
              attention
              social{
                facebook
                instagram
                twitter
                youtube
                linkedin
                tiktok
              }
              keys{
                captcha
              }
              branch_office{
                name
                address
                address_note
                city
                region
                broker_id
                contact {
                  phone
                  mail
                  whatsapp
                }
              }
              sections {
                    services{
                        title
                        subtitle
                        paragraph
                        form
                    }
                    footer {
                        title_contact
                        title_social
                        title_newsletter
                        title_office
                        title_matricula
                        button_contact {
                            link
                            value
                        }
                    }
              }
          }
    }`)
    return realEstate.sections &&
      realEstate.sections.services.form ? (
        <section className="tasaciones-main">
            <div className="container-fluid">
                <div className="row mt-lg-4 center align-items-center">
                    <div className="col-12">
                        <div className="row content-main align-items-start">
                            <div className="col-lg-7">
                               <Breadcrumbs
                                   props={[
                                   { name: realEstate.name, route: "/", location: "" },
                                   { name: "Tasaciones", route:   "", location: ""},
                                   ]}
                               />
                                <h1 className="mb-lg-5">{realEstate.sections.services.title}</h1>
                                <p className="mb-1 mt-5 mt-lg-0 subtitle">{realEstate.sections.services.subtitle}</p>
                                <p className="subtitle">
                                    {getBrText(realEstate.sections.services.paragraph).map((element) => (
                                        <>
                                            {element}
                                            <br />
                                        </>
                                    ))}
                                </p>
                                <Formulario />
                            </div>
                            <div className="col-lg-4 offset-lg-1 text-right p d-none d-lg-flex align-items-center contact-nav">
                                <div className="row">
                                    <div className="item-contact col-8 col-lg-12 mb-0">
                                        <h5 className="title">{realEstate.sections.footer.title_contact}:</h5>
                                    </div>
                                    <div className="item-contact d-none d-lg-block col-12">
                                        {/* <h5 className="subtitle">Central</h5> */}
                                        <a className="hover-service-link hover-link" target="_blank" 
                                        href={`mailto:${realEstate.global_email}`}>{realEstate.global_email}</a>
                                        <p>Tel. {realEstate.global_phone[0]}</p>
                                        <p>Whatsapp <a href={"https://api.whatsapp.com/send?text=" + getMessageWhatsapp() + "&phone=" + realEstate.global_whatsapp.replaceAll('-','').replaceAll(' ','')} target="_blank" className="hover-service-link hover-link d-inline">{realEstate.global_whatsapp}</a></p>
                                    </div>
                                    <div className="item-contact col-8 col-lg-12 mb-0">
                                        <h5 className="title">ENCONTRAN0S:</h5>
                                        {/* <p>{realEstate.attention}</p> */}
                                    </div>
                                    {realEstate.branch_office.length > 0 &&
                                    realEstate.branch_office.map((item,index) => (
                                        <div className="item-contact d-none d-lg-block col-12" key={index}>
                                            {/* <h5 className="subtitle">{item.name}:</h5> */}
                                            <p>{item.address} {item.address_note ? <span className="note">{item.address_note}</span> : null}</p>
                                            <p>{item.city}</p>
                                            <p>{item.region}</p>
                                            <p className={item.contact.whatsapp === '' && 'd-none'}>Whatsapp +{ item.contact.whatsapp }</p>
                                        </div>
                                    ))}
                                    <div className="item-contact col-8 col-lg-12">
                                        <h5 className="title">{realEstate.sections.footer.title_matricula}:</h5>
                                        <p>{realEstate.global_broker_id[0]} <br/> {realEstate.global_broker_id[1]}</p>
                                        {/* <div className="col-6 offset-6 col-lg-12 offset-lg-0 div-logos d-flex">
                                            <img src={logo1} alt="CIA" className="cia"/>
                                            <img src={logo2} alt="SOM" className="som"/>
                                        </div> */}
                                    </div>
                                    <div className="item-contact social col-4 col-lg-12">
                                        <h5 className="title">SEGUINOS:</h5> 
                                        <a target="_blank" href={realEstate.social.instagram} className={realEstate.social.instagram ? "d-inline" : 'd-none'}><i className="icon-instagram"></i></a>
                                        <a target="_blank" href={realEstate.social.facebook} className={realEstate.social.facebook ? "d-inline" : 'd-none'}><i className="icon-facebook"></i></a>
                                        <a target="_blank" href={realEstate.social.linkedin} className={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i className="icon-linkedin"></i></a>
                                        <a target="_blank" href={realEstate.social.youtube} className={realEstate.social.youtube ? "d-inline" : 'd-none'}><i className="icon-youtube"></i></a>
                                        <a target="_blank" href={realEstate.social.twitter} className={realEstate.social.twitter ? "d-inline" : 'd-none'}><i className="icon-twitter"></i></a>
                                        <a target="_blank" href={realEstate.social.tiktok} className={realEstate.social.tiktok ? "d-inline" : 'd-none'}><i className="icon-tiktok"></i></a>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-3 offset-lg-1 text-right contact-nav d-none d-lg-flex align-items-center">
                                <div class="center-contact">
                                    <div class="item-contact">
                                        <h5>{realEstate.sections.footer.title_contact}</h5>
                                        <a class="hover-service-link" target="_blank" href={"mailto:" + realEstate.global_email}>{realEstate.global_email}</a>
                                    </div>
                                    <div className="max-heigth-scroll">
                                        {realEstate.branch_office.map((item,index) => (
                                            <div class="item-contact">
                                                <h5>{realEstate.sections.footer.title_office + " "}{item.name}</h5>
                                                <p >{item.address}</p>
                                                <p >{item.region}</p>
                                                <a target="_blank" className={"hover-link " + (!item.contact.mail && 'd-none')} href={"mailto:"+item.contact.mail}>{item.contact.mail}</a>
                                                {item.contact.phone.map(phone => (
                                                    <p >Teléfono {phone}</p>
                                                ))}
                                                <p className={item.contact.whatsapp === '' && 'd-none'}>Whatsapp +{ item.contact.whatsapp }</p>
                                                <p className={item.broker_id === '' && 'd-none'}>Matrícula {item.broker_id}</p>
                                            </div>
                                        ))} 
                                    </div>
                                    <div class="item-contact">
                                        <h5 className='mb-3'>SEGUINOS</h5> 
                                        <a target="_blank" href={realEstate.social.instagram} class={realEstate.social.instagram ? "d-inline" : 'd-none'}><i class="icon-instagram"></i></a>
                                        <a target="_blank" href={realEstate.social.facebook} class={realEstate.social.facebook ? "d-inline" : 'd-none'}><i class="icon-facebook"></i></a>
                                        <a target="_blank" href={realEstate.social.linkedin} class={realEstate.social.linkedin ? "d-inline" : 'd-none'}><i class="icon-linkedin"></i></a>
                                        <a target="_blank" href={realEstate.social.youtube} class={realEstate.social.youtube ? "d-inline" : 'd-none'}><i class="icon-youtube"></i></a>
                                        <a target="_blank" href={realEstate.social.twitter} class={realEstate.social.twitter ? "d-inline" : 'd-none'}><i class="icon-twitter"></i></a>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    ):''
}

export default Main
import React from 'react'
import {getBrText} from '../../helpers/helper.rendering'
import { graphql, useStaticQuery } from 'gatsby';

const Admin = () => {

    const { realEstate } = useStaticQuery(graphql`
    query {
        realEstate {
            sections {
                  services{
                    management{
                        title
                        paragraph
                        items
                    }
                  }
            }
        }
    }`)

    return realEstate.sections && (
        <section class="tasaciones-administracion">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2">
                        <p class="title-section">{realEstate.sections.services.management.title}</p>
                    </div>
                    <div class="col-lg-10">
                        <h2 className='text-section-component'>
                            {getBrText(realEstate.sections.services.management.paragraph).map((item, index) => (
                                <>
                                    {item}
                                    <br className="d-none d-lg-block" key={index} />
                                </>
                            ))}
                        </h2>
                    </div>
                    <div class="col-lg-10 offset-lg-2">
                        <div class="row items">
                            {realEstate.sections.services.management.items.map((item, index) => (
                                item !== '' && 
                                <div className={"col-lg-6 d-flex align-items-start " + (index < 2 ? "mb-3 mb-lg-5" : "mb-3")} key={index}>
                                    <i className="icon-checked"></i>
                                    <p>
                                        {item}
                                        <br className="d-none d-lg-block" key={index} />
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Admin